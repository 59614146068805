<template>
  <div>
    <section class="self_sec">
      <div class="container-fluid">
        <div v-if="loading" class="loader"></div>
        <div class="row">
          <div class="col-md-6">
            <div class="login_signup_form py_60 mt_10">
              <FlashMessage :message="message" :error="error" />
              <h2 class="text_black font_size_36">Password Encryption</h2>
              
              <form @submit.prevent="forgotPassword">
                <div class="form-group">
                  <label class="font_size_24 mt_20 font_bold" style="font-size: 18px;">Kindly Enter The OTP received at your Email Address</label>
                  <input
                    type="number"
                    placeholder="Enter OTP"
                    class="mb_50 form-control"
                  />
                </div>
                <div
                  class="
                    mt_68
                    d-flex
                    justify-content-between
                    align-items-center
                    btn_link_box
                  "
                >
                  <div>
                    <!-- <button class="btn btn_primary">Submit</button> -->
                    <a href="https://production.pbergo.com/admin" class="btn btn_primary">Submit</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 p-0">
            <div class="self_img">
              <img src="../assets/images/schedule_img.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
    name:"password_encryption"
}
</script>